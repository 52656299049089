<template>
  <div
    v-show="show"
    class="wrapper"
    :style="realWrapperStyle"
    @click="onMaskClick"
    @touchmove.stop.prevent
  >
    <div class="bd" :class="{w100: ['top', 'bottom'].includes(this.direction)}" :style="realBodyStyle" @click.stop>
      <slot />
    </div>
  </div>
</template>

<script>
import {getWindowHeight, px2vw} from '@/utils/common'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MgPopup',
  props: {
    direction: {
      type: String,
      default: 'top'
    },
    wrapperStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    bodyStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 98
    },
    maskClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      translateX: 0,
      translateY: 0,
      timer: null,
      toggle: false
    }
  },
  computed: {
    realWrapperStyle() {
      const data = {
        height: `${getWindowHeight()}px`,
        zIndex: this.zIndex
      }

      return {...data, ...this.wrapperStyle}
    },
    realBodyStyle() {
      const data = {
        top: {
          height: `${this.height}px`,
          top: 0,
          zIndex: this.zIndex + 1
        },
        bottom: {
          height: `${this.height}px`,
          bottom: 0,
          zIndex: this.zIndex + 1
        },
        left: {
          width: `${px2vw(this.width)}vw`,
          height: `${getWindowHeight()}px`,
          left: 0,
          zIndex: this.zIndex + 1
        },
        right: {
          width: `${px2vw(this.width)}vw`,
          height: `${getWindowHeight()}px`,
          right: 0,
          zIndex: this.zIndex + 1
        }
      }

      return {
        ...data[this.direction],
        ...this.bodyStyle,
        ...this.translateStyle
      }
    },
    translateStyle() {
      const data = {
        top: {
          transform: `translateY(${this.translateY}px)`
        },
        bottom: {
          transform: `translateY(${this.translateY}px)`
        },
        left: {
          transform: `translateX(${px2vw(this.translateX)}vw)`
        },
        right: {
          transform: `translateX(${px2vw(this.translateX)}vw)`
        }
      }

      return data[this.direction]
    }
  },
  methods: {
    open() {
      this.show = true

      setTimeout(() => {
        if (['left', 'right'].includes(this.direction)) {
          this.translateX = 0
        } else {
          this.translateY = 0
        }
      }, 100)

      setTimeout(() => this.toggle = true, 300)
    },
    close() {
      if (this.timer !== null || !this.toggle) {
        return
      }

      switch (this.direction) {
        case 'top':
          this.translateY = -this.height
          break
        case 'bottom':
          this.translateY = this.height
          break
        case 'left':
          this.translateX = -this.width
          break
        case 'right':
          this.translateX = this.width
          break
      }

      this.timer = setTimeout(() => {
        this.show = false
        this.timer = null
        this.toggle = false
        this.$emit('afterClose', null)
      }, 300)
    },
    onMaskClick() {
      this.maskClose && this.close()
    },
    getState() {
      return this.show ? 1 : 0
    }
  },
  mounted() {
    switch (this.direction) {
      case 'top':
        this.translateY = -this.height
        break
      case 'bottom':
        this.translateY = this.height
        break
      case 'left':
        this.translateX = -this.width
        break
      case 'right':
        this.translateX = this.width
        break
    }
  }
}
</script>

<style lang="less">
.wrapper {
  width: 100%;
  background: rgba(0, 0, 0, .3);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bd {
  background: #fff;
  transition: all .3s ease;
  position: fixed;
  overflow: hidden;

  &.w100 {
    width: 100%;
  }
}
</style>