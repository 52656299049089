import { getWindowHeight } from '@/utils/common'
import { getRandomString, substringBeforeLast } from '@/utils/string'
import qs from 'qs'
import { apiPost } from '@/utils/request'
import { isEmpty, isFunction, isObject, isApiResult } from '@/utils/assert'
import { localStorageGet, localStorageSet } from '@/utils/storage'
import { cacheKeys } from '@/config/CacheKeys'
import customEvents from '@/config/custom_events'
import bus from '../libs/bus'

async function codeToWxuser(code) {
    let apiResult

    try {
        apiResult = await apiPost('wxgzh.code2userinfo', { code })
    } catch (ex) {
        apiResult = null
    }

    if (!isApiResult(apiResult)) {
        return Promise.resolve()
    }

    const { memberId, openid, nickname, avatarUrl, jwt } = apiResult.obj || {}
    localStorageSet(cacheKeys.jwt, jwt)
    localStorageSet(cacheKeys.wxuser, { memberId, openid, nickname, avatarUrl })
    return Promise.resolve()
}

export default {
    data() {
        return {
            jwt: {},
            wxuser: {},
            windowHeight: 667,
            isDataLoaded: false
        }
    },
    async beforeRouteEnter(to, from, next) {
        console.log("有code请求", to)
        const { code } = to.query || {}

        if (code) {
            await codeToWxuser(code)
        }

        next()
    },
    methods: {

        toLink(url) {
            if (isEmpty(url)) {
                return
            }

            window.location.href = url
        },
        // 异步设置网页标题
        asyncTile(title) {
            document.title = title;
            let iframe = document.createElement('iframe');
            //   iframe.src = require('/favicon.ico');
            iframe.style.display = 'none';
            let fn = function () {
                setTimeout(function () {
                    iframe.removeEventListener('load', fn);
                    document.body.removeChild(iframe);
                }, 0);
            };
            iframe.addEventListener('load', fn);
            document.body.appendChild(iframe);
        },
        checkRnd() {
            let { path, query } = this.$route

            if (!query) {
                query = {}
            }

            if (query.rnd) {
                return Promise.resolve(true)
            }

            query.rnd = getRandomString(8, 3)

            const sb = [
                substringBeforeLast(window.location.href, '/'),
                path,
                `?${qs.stringify(query)}`
            ]
            window.location.href = sb.join('')
            return Promise.resolve(true)
        },

        async fenxiaoBind() {
            let srcMemberId = localStorageGet("qdSrcMemberId")

            if (this.isEmpty(srcMemberId)) {
                // localStorageRemove("srcMemberId")
                return
            }

            // alert(srcMemberId)

            // localStorageSet("srcMemberId", srcMemberId)

            const promise = this.apiPost('fenxiao.bind', { srcMemberId })

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            if (this.isEmpty(apiResult)) {
                return
            }
        },
        funEvaluate(tips, ...args) {
            let opts = null
            let btnCallback = null

            for (let arg of args) {
                if (isFunction(arg)) {
                    btnCallback === null ? btnCallback = arg : false
                    continue
                }

                if (isObject(arg)) {
                    opts === null ? opts = arg : false
                }
            }

            if (opts === null) {
                opts = {}
            }

            bus.$emit(customEvents['evaluate.show'], { ...opts, tips, btnCallback })
        }
    },
    created() {
        this.windowHeight = getWindowHeight()
    },
    mounted() {
        this.jwt = localStorageGet(cacheKeys.jwt) || {}
        this.wxuser = localStorageGet(cacheKeys.wxuser) || {}
    }
}